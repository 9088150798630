<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <i class="icon-menu mr-1"></i>Data Table Pop Up Home Page
            </b-card-header>
            <b-card-body>
                <new-users></new-users>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import newUsers from './Table'
export default {
    name: 'popup-home-pages',
    components: {
        newUsers,
    },
}
</script>
